import type { FetchOptions } from '@canalplus/mycanal-fetch';
import type { LogConfig } from '@canalplus/mycanal-logger';
import type { BusinessTypes } from '@canalplus/oneplayer-constants';
import type { IMappingConfig, TEnv } from '@canalplus/oneplayer-types';
import type { AcmSdkConfig } from '@canalplus/sdk-acm';
import type {
  Environment,
  HodorPlatform,
  Lang,
  OfferLocation,
  Operator,
  Platform,
  Zone,
} from '@canalplus/sdk-core';
import type { HodorSdkConfig, InitParameters } from '@canalplus/sdk-hodor';
import type { PassSdkConfig } from '@canalplus/sdk-pass';

// ⚠️ Do not import from source since it may cause deployment issues
type HapiXxService = 'mycanal' | 'cplay';

type Cache = {
  page_cache: boolean;
  expiration_time: string;
  application_tree: string;
  ws_from_path: string;
  format: string;
};

export type PassClientIds = {
  default: string;
  [hostname: string]: string;
};

type TimeoutPaymentProvider = {
  default: number;
  processPayment: number;
  funnelContent: number;
};

type PaymentProvider = {
  base_url: string;
  bom_base_url: string;
  path_paymentmeans: string;
  path_process_payment: string;
  path_delete_credit_card: string;
  path_redirect_payment: string;
  path_getAddresses: string;
  path_getDeleveryAddresses: string;
  addressValidOnly: boolean;
  user_id: string;
  timeout: TimeoutPaymentProvider;
  distributor_id: string;
  sale_device: string;
};

type Platforms = {
  [T in Exclude<Platform, 'OrangeKey'>]: {
    arboVersion: HodorSdkConfig['arboVersion'];
    configVersion: string;
    device: HodorPlatform;
  };
};

type ConfigHodor = {
  abTestingPercentage: number;
  defaultAppKey: AppKey;
  baseUrl: HodorSdkConfig['baseUrl'];
  paths: HodorSdkConfig['paths'] & {
    avatar: `/{media}/{portailId}/${string}`;
    getFavoritesLiveGrid: `/${string}/{cmsToken}/${string}`;
    list: `/${string}/{cmsToken}/${string}/{listType}/${string}`;
    privacy: `/${string}/{cmsToken}/${string}`;
    profile: `/${string}/{media}/{portailId}/${string}`;
    profileUrl: string;
    saveFavoritesLiveGrid: `/${string}/{cmsToken}/${string}`;
    termsOfSale: `/${string}/{cmsToken}/${string}`;
    robotsTxtPath: string;
    sitemapXmlPath: string;
    expertModeVote: string;
  };
  platforms: Platforms;
  fetchOptions: FetchOptions;
  initParameters: Required<Pick<InitParameters, 'allowedProfiles'>> &
    Omit<InitParameters, 'allowedProfiles'>;
  profileParameters: {
    profilesTemplateVersion: string;
  };
};

type ConfigPass = {
  baseUrl: PassSdkConfig['baseUrl'];
  clientIds: PassClientIds;
  fetchOptions: FetchOptions;
};

type Api = {
  acm: { endpoint: AcmSdkConfig['endpoint'] };
  address: { url: string };
  hapi: {
    base_url: string;
    xx_service: HapiXxService;
    timeout: number;
    path_pulling_hapi_redirect_payment: string;
  };
  hodor: ConfigHodor;
  pass: ConfigPass;
  paymentPageBaseUrl: string;
  paymentProvider: PaymentProvider;
  redirect_host: string;
  sale_status: { path: string };
  update_user_profile: { idClient: string };
};

type Meta = {
  titleTemplate: string;
  twitter: { twitterAccount: string; urlImageDefault: string };
  meta: Array<{
    name?: string;
    content?: string;
    charset?: string;
    property?: string;
  }>;
  link: Array<Link>;
  defaultHostname: string;
};

type Link = {
  rel?: string;
  href?: string;
  sizes?: string;
  media?: string;
};

export type MetasType = {
  app: {
    title: string;
    description: string;
    head: Meta;
  };
};

type Monitoring = {
  title: string;
  head: {
    titleTemplate: string;
    meta: Array<{ name?: string; content?: string; charset?: string }>;
  };
};

export type DocumentHeader = {
  [OfferLocation.pl]: MetasType;
  [OfferLocation.en]: MetasType;
  [OfferLocation.it]: MetasType;
  default: MetasType;
  monitoring: Monitoring;
};

type DefaultLocale = {
  offerLocation: Extract<OfferLocation, 'fr' | 'it'>;
  langKey: Extract<Lang, 'fr' | 'it'>;
};

type Player = {
  embededUrl: string;
  ONE_PLAYER: {
    BASE_URLS: {
      default: string;
      [Platform.LG]: string;
      [Platform.PlayStation4]: string;
    };
    BUNDLE_NAME: string;
    CONFIG_BASE_URL?: string;
    CONTEXT: keyof IMappingConfig;
    MINIMAL_BUNDLE_NAME: string;
    ONE_PLAYER_ENV: TEnv;
    VARIANT: `${BusinessTypes.VARIANTS}`;
  };
  PLATFORM: {
    LIVE: string;
    VOD: string;
    ATG: string;
    HAPI: string;
    PFV: string;
    DOWNLOADTOGO: string;
  };
  DOWNLOAD_TO_GO: {
    BASE_URL: string;
    BUNDLE_NAME: string;
  };
};

type Reviews = {
  TYPE: {
    ALLOCINE: string;
  };
};

export enum TrackingEnvironment {
  Prod = 'prod',
  Staging = 'staging',
}

type EnvironmentConfig = {
  'beta.': TrackingEnvironment.Staging;
  'default': TrackingEnvironment.Prod;
  'dev.': TrackingEnvironment.Staging;
  'int.': TrackingEnvironment.Staging;
  'local.': TrackingEnvironment.Staging;
  'pprod.': TrackingEnvironment.Staging;
  'rec.': TrackingEnvironment.Staging;
  'review-app-': TrackingEnvironment.Staging;
};

type Omniture = {
  OMNITURE_REPORTSUITE: string | null;
  BUNDLE: string;
  REPORTSUITE: null;
  DTM_KEY: string;
  TEALIUM_KEY: string;
  CHANNEL: string;
  FILTER: string;
  ENVIRONMENT: EnvironmentConfig;
};

type TrackingConfig = {
  PASS_CONF: {
    DEFAULT_CONF: {
      msg_act_ok_gene: string;
      pwdreinit_gene_gene: string;
      pwdmod_gene_gene: string;
    };
  };
};

type Tracking = {
  OMNITURE: Omniture;
  CONFIG: TrackingConfig;
};

type Footer = {
  socialLinks: {
    facebook: string;
    twitter: string | null;
    dailymotion: string | null;
    youtube: string | null;
  };
};

type Log = {
  client: LogConfig;
  server: LogConfig;
};

type Debug = {
  externalReactDevTools: boolean;
  reactQueryDevtools: boolean;
};

type App = {
  account: {
    base_url: `https://${string}`;
    integration_base_url: `https://${string}.int.${string}`;
  };
};

export enum Overlay {
  MyCanal = 'mycanal',
  TIM = 'tim',
  TVODStandalone = 'tvodstandalone',
}

const AppKeys = [
  'mycanal',
  'mycanalint',
  'timvision',
  'canalvod',
  'mycanaleth',
  'mycanalchde',
] as const;

export type AppKey = (typeof AppKeys)[number];

export const isAppKey = (appKey: string): appKey is AppKey =>
  AppKeys.includes(appKey as AppKey);

export type Public = {
  operator: Operator;
  mock: boolean;
  documentHeader?: DocumentHeader;
  cache: Cache;
  cookie: {
    /**
     * maxAge in days
     */
    maxAge: number;
  };
  environment: Environment;
  app: App;
  api: Api;
  defaultLocale: DefaultLocale;
  zones: Zone;
  PLAYER: Player;
  MICRO_ELIGIBILITY_FILTERS: {
    LIVE: Array<string>;
    VOD: Array<string>;
  };
  REVIEWS: Reviews;
  TRACKING: Tracking;
  DIDOMI?: { ACTIVE?: boolean; API_PUBLIC_KEY?: string };
  DOWNLOADTOGO: {
    CUSTOM_PROTOCOL_URL: string;
    ALLOWED_OFFER_LOCATION: OfferLocation[];
  };
  DOWNLOAD_TO_GO?: { BASE_URL: '' };
  CGV: { link: string };
  CANALVOD: { link: string };
  footer: Footer;
  search_results: {
    personality: string;
  };
  offline: {
    url: string;
  };
  user_status: {
    prospect: 'prospect';
    abonne: 'abonne';
  };
  log: Log;
  debug: Debug;
  overlay: Overlay;
  systemPaySdk: string;
  appSmartBanners: {
    iOSAppID: string;
  };
  newrelic: {
    path: {
      [offerLocation: string]: string;
    };
  };
  proxificationList?: ProxificationList;
  timeout: Record<Timeout, number>;
};

export type Timeout = '250ms' | '500ms' | '900ms' | '1s' | '2s';

type ApiServer = {
  pass: {
    clientSecrets: PassClientIds;
  };
};

type Rewrite = {
  [key: string]: {
    [key: string]: string;
  };
};

export type RewriteRule = {
  match: {
    request: {
      path: `/${string}`;
      query?: Record<string, string>;
    };
    overlay?: Overlay;
    offerLocation?: OfferLocation;
  };
  to: `/${string}`;
};

type Server = {
  port: number;
  keepAliveTimeout: number;
  headersTimeout: number;
  ssr: boolean;
  api: ApiServer;
  multithread: boolean;
  timeout: number;
  cookie: {
    /**
     * max_age in days
     */
    maxAge: number;
  };
  proxyUrl?: string;
  rewrites: Rewrite;
  rewriteRules: RewriteRule[];
  frameAncestors: string[];
};

type Tests = {
  unit: {
    server: {
      port: number;
    };
  };
  load: {
    max: number;
    mean: number;
  };
};
type ProxiItem = { from: string; to: string };

export type ProxificationList = ProxiItem[];

export type ConfigType = {
  public: Public;
  server: Server;
  apm: { region: string; tag: string; label: string };
  cache: { assets: { max_age: number; always_refresh: boolean } };
  assets_extensions: Array<string>;
  log: { store: boolean };
  tests?: Tests;
  proxificationList?: ProxificationList;
};
