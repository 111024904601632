import { addQueryParam, getObjectKeys } from '@canalplus/mycanal-commons';

export const getUrlWithQueries = (
  url: string,
  queryParameters: Record<string, string | number | boolean | undefined>,
): string =>
  getObjectKeys(queryParameters).reduce((accumulator, key) => {
    const value = queryParameters[key];

    if (value === undefined || value === '') {
      return accumulator;
    }

    return addQueryParam(accumulator, key, encodeURIComponent(String(value)));
  }, url);
