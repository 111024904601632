import type { LogApiMetadata } from '@canalplus/mycanal-logger';
import {
  PassError,
  createToken,
  type CreateTokenParameters,
  type CreateTokenResponse,
  type PassSdkConfig,
} from '@canalplus/sdk-pass';
import Logger from '../../helpers/logger/logger-helper';

export async function performCreateToken(
  passSdkConfig: PassSdkConfig,
  createTokenParameters?: CreateTokenParameters,
): Promise<CreateTokenResponse> {
  try {
    return await createToken(passSdkConfig, {
      ...createTokenParameters,
    });
  } catch (error) {
    if (error instanceof PassError) {
      Logger.info(
        `[getUserInfos] createToken has failed with error code ${error.errorCode}, a prospect createToken will be performed in case of invalid identity`,
        Logger.generateMetadata({
          service: 'myCanal',
          requestId: passSdkConfig.logMetadata?.requestId,
        } as LogApiMetadata).meta,
      );
      // errorCode 1 means "Missing, invalid or expired P@SS identity"
      if (error.errorCode === 1) {
        // perform another createToken, but this time as prospect (without the erroneous passId param)
        return await createToken(passSdkConfig, {
          ...createTokenParameters,
          passId: undefined,
        });
      }
    }

    throw error;
  }
}
