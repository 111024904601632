import { DisplayMode } from '@canalplus/mycanal-commons';
import { createSelector } from '@reduxjs/toolkit';
import type { Immersive } from '../../store/slices/immersive';
import {
  pageDisplayNameSelector,
  pageDisplayTemplateSelector,
  pagePathSelector,
} from '../../store/slices/page-selectors';
import { displayModeSelector } from '../../store/slices/ui-selectors';
import type { IState } from '../../store/types/State-type';

export const immersiveSelector = (state: IState): Immersive => state?.immersive;

const immersiveMainOnclickSelector = createSelector(
  immersiveSelector,
  (immersive) => immersive?.mainOnClick,
);

export const immersiveDisplayNameSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.displayName,
);

export const immersiveDisplayTemplateSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.displayTemplate,
);
export const immersiveURLPageSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.URLPage,
);

export const immersivePathSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.path || '',
);

export const immersiveAdultSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.adult ?? false,
);

export const immersiveTrackingContextSelector = createSelector(
  immersiveSelector,
  (immersive) => immersive?.mainOnClick?.trackingContext || {},
);

export const immersiveTrackingSelector = createSelector(
  immersiveSelector,
  (immersive) => immersive?.tracking || {},
);

export const isImmersiveSelector = createSelector(
  immersiveSelector,
  (immersive): boolean => immersive?.active || false,
);

// whether other element is opened in immersive (e.g modal, player)
export const hasFocusImmersiveSelector = createSelector(
  isImmersiveSelector,
  displayModeSelector,
  (isImmersive, displayMode) =>
    isImmersive &&
    (displayMode === DisplayMode.WINDOWED || displayMode === DisplayMode.MODAL),
);

export const compoundDisplayNameSelector = createSelector(
  [immersiveDisplayNameSelector, pageDisplayNameSelector],
  (immersiveDisplayName, pageDisplayName) =>
    immersiveDisplayName || pageDisplayName,
);

export const immersiveOrPageNameSelector = createSelector(
  [isImmersiveSelector, immersiveDisplayNameSelector, pageDisplayNameSelector],
  (active, displayName, pageDisplayName) =>
    active ? displayName : pageDisplayName,
);

export const immersiveOrPageTemplateSelector = createSelector(
  [
    isImmersiveSelector,
    immersiveDisplayTemplateSelector,
    pageDisplayTemplateSelector,
  ],
  (active, displayTemplate, pageDisplayTemplate) =>
    active ? displayTemplate : pageDisplayTemplate,
);

export const immersiveOrPagePathnameSelector = createSelector(
  [isImmersiveSelector, immersivePathSelector, pagePathSelector],
  (active, immersivePath, pagePath) => (active ? immersivePath : pagePath),
);

export const isImmersivePersoUpdated = createSelector(
  immersiveSelector,
  (immersive) => immersive?.persoUpdated || false,
);

export const clickedElementIdSelector = createSelector(
  immersiveSelector,
  (immersive) => immersive?.clickedElementId,
);

export const immersiveParametersSelector = createSelector(
  immersiveMainOnclickSelector,
  (mainOnClick) => mainOnClick?.parameters || [],
);
