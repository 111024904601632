import { fetch } from '@canalplus/mycanal-fetch';
import type { LogApiMetadata } from '@canalplus/mycanal-logger';
import { Service } from '@canalplus/mycanal-logger';
import { HodorError } from '../error/HodorError';
import { HodorInvalidPassTokenError } from '../error/HodorInvalidPassTokenError';
import { HodorMissingPassTokenError } from '../error/HodorMissingPassTokenError';
import { logError } from '../error/helpers';
import { getRequestParameters } from './getRequestParameters';
import type { FetchHodorParameters } from './types';

export const fetchHodor = async ({
  api,
  body,
  hodorSdkConfig,
  hodorSdkParameters = [],
  startAt,
  url,
}: FetchHodorParameters): Promise<Response> => {
  const {
    appKey,
    cmsToken,
    fetchOptions,
    logger,
    logMetadata,
    offerLocation,
    offerZone,
    passTokenRenewalCallback,
    requestId,
    rewriteUrlCallback,
  } = hodorSdkConfig;

  const { headers: computedHeaders, endpoint } = getRequestParameters({
    hodorSdkConfig,
    hodorSdkParameters,
    url,
  });

  const rewrittenEndpoint = rewriteUrlCallback?.(endpoint) || endpoint;

  const baseLogs: LogApiMetadata = {
    ...logMetadata,
    endpoint: rewrittenEndpoint,
    offerLocation,
    offerZone,
    startAt,
    cmsToken,
    req: {
      appData: { appKey },
      ...(requestId && { requestId }),
    },
  } as const;

  try {
    const response = await fetch(rewrittenEndpoint, {
      ...fetchOptions,
      headers: {
        ...fetchOptions?.headers,
        ...computedHeaders,
      },
      ...(body && { body }),
    });

    const loggerParameters = [
      `HODOR ${api} ${response.status}`,
      logger.generateApiMetadata(Service.Hodor, api, {
        ...baseLogs,
        statusCode: response.status,
      }),
    ] as const;

    logger.info(...loggerParameters);

    if (!response.ok) {
      switch (response.status) {
        case 401:
          throw new HodorMissingPassTokenError(response.status);

        case 403:
          throw new HodorInvalidPassTokenError(response.status);

        case 404:
          // Hodor Api sends information like tracking, content, displayTemplate...
          // so the error must not be thrown
          logger.error(...loggerParameters);

          break;

        default:
          throw new HodorError(response.status);
      }
    }

    return response;
  } catch (error) {
    if (
      error instanceof HodorInvalidPassTokenError &&
      passTokenRenewalCallback
    ) {
      const newPassToken = await passTokenRenewalCallback();

      logger.info(
        `HODOR ${api} ${error.statusCode} ${error.name} has been thrown, retrying with a new passToken`,
        logger.generateApiMetadata(Service.Hodor, api, { ...baseLogs }),
      );

      return fetchHodor({
        api,
        body,
        hodorSdkConfig: {
          ...hodorSdkConfig,
          passToken: newPassToken,

          // `passTokenRenewalCallback` is removed from the second call to avoid an infinite loop
          passTokenRenewalCallback: undefined,
        },
        hodorSdkParameters,
        startAt,
        url,
      });
    }

    logError({ error, logger, baseLogs, api });

    throw error;
  }
};
