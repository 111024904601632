import { getUrlOrigin } from '@canalplus/mycanal-commons';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { Platform } from '@canalplus/sdk-core';
import { Template } from '@canalplus/sdk-hodor';
import { DiveTokensBrands } from '@dce-front/dive-tokens';
import type { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import Cookies from 'js-cookie';
import { Overlay } from '../../../config/application/types';
import { ApplicationTheme, Dark } from '../../constants/applicationTheme';
import { CookieKey } from '../../constants/cookie';
import { getPublicConfig } from '../config/config-helper';

const defaultDeviceType = BusinessTypes.DEVICE_TYPES.DESKTOP_MOBILE;

const DEVICE_TYPES = {
  [Platform.FireTV]: defaultDeviceType,
  [Platform.G10]: defaultDeviceType,
  [Platform.G10LCPI]: defaultDeviceType,
  [Platform.G11]: defaultDeviceType,
  [Platform.G9]: defaultDeviceType,
  [Platform.G9LBis]: defaultDeviceType,
  [Platform.G9LCPI]: defaultDeviceType,
  [Platform.G9LCPISat]: defaultDeviceType,
  [Platform.G9LNCP]: defaultDeviceType,
  [Platform.G9Mini]: defaultDeviceType,
  [Platform.G9Sat]: defaultDeviceType,
  [Platform.G9SE]: defaultDeviceType,
  [Platform.Hisense]: BusinessTypes.DEVICE_TYPES.HISENSE,
  [Platform.LG]: BusinessTypes.DEVICE_TYPES.LG,
  [Platform.OrangeKey]: defaultDeviceType,
  [Platform.OrangeManhattan]: defaultDeviceType,
  [Platform.OrangeMib4]: defaultDeviceType,
  [Platform.OrangePlay]: defaultDeviceType,
  [Platform.OrangeStellar]: defaultDeviceType,
  [Platform.Philips]: 'philips',
  [Platform.PlayStation4]: defaultDeviceType,
  [Platform.PlayStation5]: defaultDeviceType,
  [Platform.Samsung]: BusinessTypes.DEVICE_TYPES.SAMSUNG,
  [Platform.SFR7IPTV]: defaultDeviceType,
  [Platform.SFR8Cable]: defaultDeviceType,
  [Platform.SFR8IPTV]: defaultDeviceType,
  [Platform.Web]: defaultDeviceType,
} as const satisfies Record<Platform, BusinessTypes.DEVICE_TYPES | 'philips'>;

const defaultDeviceId = '3';

const DEVICE_IDS = {
  [Platform.FireTV]: '81',
  [Platform.G10]: '26',
  [Platform.G10LCPI]: '26',
  [Platform.G11]: '26',
  [Platform.G9]: '26',
  [Platform.G9LBis]: '26',
  [Platform.G9LCPI]: '26',
  [Platform.G9LCPISat]: '26',
  [Platform.G9LNCP]: '26',
  [Platform.G9Mini]: '26',
  [Platform.G9Sat]: '26',
  [Platform.G9SE]: '26',
  [Platform.Hisense]: '18',
  [Platform.LG]: '16',
  [Platform.OrangeKey]: '19',
  [Platform.OrangeManhattan]: defaultDeviceId,
  [Platform.OrangeMib4]: defaultDeviceId,
  [Platform.OrangePlay]: defaultDeviceId,
  [Platform.OrangeStellar]: defaultDeviceId,
  [Platform.Philips]: '80',
  [Platform.PlayStation4]: '111',
  [Platform.PlayStation5]: '111',
  [Platform.Samsung]: '15',
  [Platform.SFR7IPTV]: '121',
  [Platform.SFR8Cable]: '123',
  [Platform.SFR8IPTV]: '122',
  [Platform.Web]: defaultDeviceId,
} satisfies Record<Platform, string>;

export const OVERLAY_APPLICATION_THEMES = {
  [Overlay.TIM]: ApplicationTheme.Tim,
  [Overlay.TVODStandalone]: ApplicationTheme.Tvod,
  [Overlay.MyCanal]: ApplicationTheme.Default,
} as const satisfies Record<Overlay, ApplicationTheme>;

export const removeTokenCmsCookie = (domain: string): void =>
  Cookies.remove(CookieKey.TokenCMS, { domain, path: '/' });

/**
 * Replace placeholders in external websites URL: {zone} => offerLocation, {passId} => passId...
 *
 * @param  {array} navigation   - Navigation items
 * @param  {string} placeholder  - placeholder to replace
 * @param  {string} value       - replace value
 */
export const replacePlaceholders = (
  navigation: ApiV2NavigationItem[],
  placeholder: string,
  value: string = '',
): any => {
  const template = new RegExp(`{${placeholder}}`);

  return (navigation || []).map((item) => {
    if (
      item.onClick?.displayTemplate === Template.ExternalSite &&
      item?.onClick?.URLWebsite
    ) {
      item.onClick.URLWebsite = item.onClick.URLWebsite.replace(
        template,
        value,
      );
    }
    return item;
  });
};

/**
 * Returns the current theme combining dark or light mode, app theme and DiveTokensBrands
 * @param mainTheme Theme of the app (e.g. `default` for myCANAL, `tvod`, `telecomitalia`...)
 */
export const getCurrentTheme = (
  mainTheme: ApplicationTheme,
  isBlueTim?: boolean,
): string => {
  switch (mainTheme) {
    case ApplicationTheme.Tvod:
      return `${mainTheme} ${Dark} ${DiveTokensBrands.Vod}`;
    case ApplicationTheme.Tim:
      if (isBlueTim) {
        return `${mainTheme} ${Dark} ${DiveTokensBrands.NewTim}`;
      } else {
        return `${mainTheme} ${Dark} ${DiveTokensBrands.Tim}`;
      }
    case ApplicationTheme.Default:
    default:
      return `${Dark} ${DiveTokensBrands.MyCanal}`;
  }
};

/** `dive-tokens` branded stylesheets pathnames. Used for testing purposes. */
export const DT_PATHNAMES = {
  tvod: '@canalplus/dive/dist/css/variables/vod-dark.css',
  default: '@canalplus/dive/dist/css/variables/canal-dark.css',
  telecomitalia: '@canalplus/dive/dist/css/variables/telecomitalia.css',
  'NEW-TIM': '@dce-front/dive-tokens/web/css/colors.components.new-tim.css',
} as const;
export const DIVE_TOKENS_INVALID_BRAND_ERR = 'Invalid theme or brand provided';

/**
 * Dynamic import of branded `@dce-front/dive-tokens`, based on the `themeOrBrand` parameter.
 *
 * Requires one of the following mandatory imports:
 * - `@dce-front/dive-tokens/web/css/colors.core.css`
 * - `@dce-front/dive-tokens/web/css/unbranded.css`, which includes `colors.core.css`
 *
 * @param {boolean} isBlueTim - A flag indicating whether to import the "blue" variant of the TIM theme.
 * @param {ApplicationTheme | DiveTokensBrands} themeOrBrand - The theme or brand for which to import dive-tokens.
 * @returns {Promise<string>} A promise that resolves to the path of the successfully imported stylesheet.
 * @see {@link https://webpack.js.org/api/module-methods/#magic-comments `webpackChunkName` magic comments used for SSR chunk retrieval}
 */
export async function importDiveTokensBranded(
  isBlueTim: boolean,
  themeOrBrand: ApplicationTheme | DiveTokensBrands = ApplicationTheme.Default,
): Promise<string> {
  switch (themeOrBrand) {
    case ApplicationTheme.Default:
      await import(
        /* webpackChunkName: "dive-tokens-branded-default" */ '@canalplus/dive/dist/css/variables/canal-dark.css'
      );
      return DT_PATHNAMES.default;

    case ApplicationTheme.Tim:
      if (isBlueTim) {
        await import(
          /* webpackChunkName: "dive-tokens-branded-telecomitalia" */ '@dce-front/dive-tokens/web/css/colors.components.new-tim.css'
        );
        return DT_PATHNAMES['NEW-TIM'];
      } else {
        await import(
          /* webpackChunkName: "dive-tokens-branded-telecomitalia" */ '@canalplus/dive/dist/css/variables/telecomitalia.css'
        );
        return DT_PATHNAMES.telecomitalia;
      }

    case ApplicationTheme.Tvod:
      await import(
        /* webpackChunkName: "dive-tokens-branded-tvod" */ '@canalplus/dive/dist/css/variables/vod-dark.css'
      );
      return DT_PATHNAMES.tvod;

    default:
      throw new Error(DIVE_TOKENS_INVALID_BRAND_ERR);
  }
}

export const isTim = (): boolean => getPublicConfig().overlay === Overlay.TIM;

/**
 * Get the device type to send to one player
 */
export const getDeviceTypeFromPlatform = (
  platform: Platform,
): BusinessTypes.DEVICE_TYPES | 'philips' => DEVICE_TYPES[platform];

/**
 * Get the device id to send to one player
 */
export const getDeviceIdFromPlatform = (
  platform: Platform,
): string | undefined => {
  const deviceId = DEVICE_IDS[platform];

  // In onePlayer, if deviceId is undefined, it is set to 32 for Safari only or 3 by default
  return deviceId !== defaultDeviceId ? deviceId : undefined;
};

/**
 * Check if the path is a first level page
 * @param firstLevelPageList array of first level path
 * @param offerLocation string of offerLocation
 * @param path path to test
 * @returns true or false
 */
export const isFirstLevelPage = ({
  firstLevelPageList,
  offerLocation,
  path,
}: {
  firstLevelPageList: string[];
  offerLocation: string;
  path: string;
}): boolean => {
  // Remove url parameters
  const formattedPath = getUrlOrigin(path) || '';

  return (
    firstLevelPageList.includes(formattedPath) ||
    // In the event of a fallback page (Hodor 404 return), the user must be able to navigate to another page from the header
    formattedPath?.startsWith('/fallback') ||
    // Handle home page
    formattedPath === '/' ||
    formattedPath === `/${offerLocation}/` ||
    false
  );
};
