import { isSomeEnum } from '@canalplus/mycanal-commons';
import type { TOfferLocation } from '@canalplus/oneplayer-types';
import { Operator } from './operator';

/** All Canal+ OfferLocations */
export const CanalPlusOfferLocation = {
  /** Andorra */
  ad: 'ad',
  au: 'au',
  /** Austria */
  at: 'at',

  bf: 'bf',
  bi: 'bi',
  bj: 'bj',
  bl: 'bl',
  cd: 'cd',
  cf: 'cf',
  cg: 'cg',
  ch: 'ch',
  ci: 'ci',
  cm: 'cm',
  cv: 'cv',

  /** Czech Republic */
  cz: 'cz',

  dj: 'dj',

  /** Dominican Republic */
  do: 'do',

  en: 'en',
  et: 'et',
  fr: 'fr',
  ga: 'ga',
  gf: 'gf',
  gh: 'gh',
  gm: 'gm',
  gn: 'gn',
  gp: 'gp',
  gq: 'gq',
  gw: 'gw',

  /** Haiti */
  ht: 'ht',

  km: 'km',

  /** Monaco */
  mc: 'mc',

  mf: 'mf',

  mg: 'mg',
  ml: 'ml',
  mq: 'mq',
  mr: 'mr',
  mu: 'mu',
  nc: 'nc',
  ne: 'ne',
  ng: 'ng',
  /** Netherlands */
  nl: 'nl',

  ot: 'ot',
  pf: 'pf',
  pl: 'pl',
  re: 're',
  rw: 'rw',

  /** Slovakia */
  sk: 'sk',

  sl: 'sl',
  sn: 'sn',
  td: 'td',
  tg: 'tg',

  /** Trinity and Tobago */
  tt: 'tt',

  vu: 'vu',
  wf: 'wf',
  yt: 'yt',
} as const;

/** All Canal+ OfferLocations */
export type CanalPlusOfferLocation =
  (typeof CanalPlusOfferLocation)[keyof typeof CanalPlusOfferLocation];

/** {@link @canalplus/sdk-core!CanalPlusOfferLocation} type guard */
export const isCanalPlusOfferLocation = isSomeEnum(CanalPlusOfferLocation);

/** All CanalBox+ OfferLocations */
export const CanalBoxOfferLocation = {
  gp: 'gp',
  gf: 'gf',
  mq: 'mq',
  bl: 'bl',
  mf: 'mf',
  re: 're',
} as const;

/** All CanalBox+ OfferLocations */
export type CanalBoxOfferLocation =
  (typeof CanalBoxOfferLocation)[keyof typeof CanalBoxOfferLocation];

/** {@link @canalplus/sdk-core!CanalBoxOfferLocation} type guard */
export const isCanalBoxOfferLocation = isSomeEnum(CanalBoxOfferLocation);

/** All TimVision OfferLocations */
export const TimVisionOfferLocation = {
  it: 'it',
} as const;

/** All TimVision OfferLocations */
export type TimVisionOfferLocation =
  (typeof TimVisionOfferLocation)[keyof typeof TimVisionOfferLocation];

/** {@link @canalplus/sdk-core!TimVisionOfferLocation} type guard */
export const isTimVisionOfferLocation = isSomeEnum(TimVisionOfferLocation);

/** All available OfferLocations */
export const OfferLocation = {
  ...CanalPlusOfferLocation,
  ...CanalBoxOfferLocation,
  ...TimVisionOfferLocation,
} as const;

/** All available OfferLocations */
export type OfferLocation =
  | CanalPlusOfferLocation
  | CanalBoxOfferLocation
  | TimVisionOfferLocation;

/** {@link @canalplus/sdk-core!OfferLocation} type guard */
export const isOfferLocation = isSomeEnum(OfferLocation);

/** Default OfferLocation by Operator */
export const DefaultOfferLocationByOperator = {
  [Operator.CanalPlus]: CanalPlusOfferLocation.fr,
  [Operator.CanalBox]: CanalBoxOfferLocation.gp,
  [Operator.Tim]: TimVisionOfferLocation.it,
} satisfies Record<Operator, OfferLocation>;

/**
 * Type guard to ensure that a string is a valid {@link @canalplus/sdk-core!OfferLocation}\
 * for a given {@link @canalplus/sdk-core!Operator}
 */
export const isValidOfferLocation = (
  offerLocation?: string,
  operator?: Operator,
): offerLocation is OfferLocation => {
  switch (operator) {
    case Operator.CanalPlus:
      return isCanalPlusOfferLocation(offerLocation);

    case Operator.CanalBox:
      return isCanalBoxOfferLocation(offerLocation);

    case Operator.Tim:
      return isTimVisionOfferLocation(offerLocation);

    default:
      return isOfferLocation(offerLocation);
  }
};

/** Type guard to ensure that an {@link @canalplus/sdk-core!OfferLocation} is a valid `TOfferLocation` */
export const isTOfferLocation = (
  offerLocation: OfferLocation,
): offerLocation is TOfferLocation =>
  offerLocation !== OfferLocation.at &&
  offerLocation !== OfferLocation.cz &&
  offerLocation !== OfferLocation.sk;
