import { isSomeEnum } from '@canalplus/mycanal-commons';
import type { Lang } from './lang';

/** All Canal+ OfferZones */
export const CanalPlusOfferZone = {
  cpant: 'cpant',
  cpche: 'cpche',
  cpchd: 'cpchd',
  cpreu: 'cpreu',
  cpmdg: 'cpmdg',
  cpncl: 'cpncl',
  cppol: 'cppol',
  cpfra: 'cpfra',
  cpafr: 'cpafr',
  cpeng: 'cpeng',
  cpoth: 'cpoth',
  cppyf: 'cppyf',
  cpmus: 'cpmus',
  cpeth: 'cpeth',
} as const;

/** All Canal+ OfferZones */
export type CanalPlusOfferZone =
  (typeof CanalPlusOfferZone)[keyof typeof CanalPlusOfferZone];

/** All CanalBox OfferZones */
export const CanalBoxOfferZone = {
  cbant: 'cbant',
  cbreu: 'cbreu',
} as const;

/** All CanalBox OfferZones */
export type CanalBoxOfferZone =
  (typeof CanalBoxOfferZone)[keyof typeof CanalBoxOfferZone];

/** All TimVision OfferZones */
export const TimVisionOfferZone = {
  tiita: 'tiita',
} as const;

/** All TimVision OfferZones */
export type TimVisionOfferZone =
  (typeof TimVisionOfferZone)[keyof typeof TimVisionOfferZone];

/** All available OfferZones */
export const OfferZone = {
  toDefine: '',
  ...CanalPlusOfferZone,
  ...CanalBoxOfferZone,
  ...TimVisionOfferZone,
} as const;

/** All available OfferZones */
export type OfferZone = (typeof OfferZone)[keyof typeof OfferZone];

export type OfferZoneByLang = {
  default: OfferZone;
} & {
  [U in Lang]?: OfferZone;
};

/** {@link @canalplus/sdk-core!OfferZone} type guard */
export const isOfferZone = isSomeEnum(OfferZone);
