import type { ImageTypeProps } from '@canalplus/mycanal-sharedcomponent';
import type { OfferZone } from '@canalplus/sdk-core';
import type { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import type { ApiV2StartupNotification } from '@dce-front/hodor-types/api/v2/me/startup_notifications/definitions';
import type { IPaymentMeans } from '../../api/PaymentMeansApi/PaymentMeansApi.type';
import type { StringNumberBoolean } from '../../typings/commons';

export type UserState = {
  abTestingPercentage: number;
  abTestingPopulation?: number;
  accessibleChannels: AccessibleChannel[];
  areAccessibleChannelsLoaded?: boolean;
  accountId?: number;
  adToken?: string;
  analyticsId?: string;
  anonymousId?: string;
  authenticated: boolean;
  avatars: ApiV2AvatarV1Content[];
  cgaNumber: string;
  displayProfileMenu: boolean;
  epgidOTT: string;
  epgid: string;
  externalServices: UserExternalServices[];
  idpToken?: string;
  isFetching: boolean;
  isPartner: boolean;
  macroEligibility: string;
  microEligibility: string;
  offerZone: OfferZone;
  passId?: string;
  passToken: string;
  passTokenCreationDate: number;
  playlistPage: any;
  profile: UserProfileState;
  profiles: ApiV2Profile[];
  reco: boolean;
  sale: UserSale;
  segType?: string;
  settings: UserSettingsState;
  subscriberId: number;
  targetedAds: StringNumberBoolean;
  waitForPassFinish: boolean;
};

export type UserNotification = ApiV2StartupNotification & {
  hidden?: boolean;
};

export enum LiveQuality {
  AUTO = 'auto',
  UHD = 'UHD',
  HD = 'HD',
  SD = 'SD',
}

export type UserSettingsState = {
  areAnimationsAutoplay?: boolean;
  areAnimationsMuted?: boolean;
  areAnimationsTemporarilyUnmuted?: boolean;
  hasAnalyticsCollected?: boolean;
  hasAnonymousTracking?: boolean;
  hasTrackingPubCollected?: boolean;
  hasUserDataCollected?: boolean;
  imageQualityPercentage?: ImageTypeProps['imageQualityPercentage'];
  liveQuality?: LiveQuality;
  isNextEpisodeAutoplay?: boolean;
  isTrailerAutoplay?: boolean;
  isTrailerMuted?: boolean;
  isHDR?: boolean;
  isLowLatency?: boolean;
  showMoodExplanation?: boolean;
};

type UserProfileState = {
  gender: number;
  firstname: string;
  lastname: string;
  nickname: string;
  email: string;
  profilesUpdateDate: string;
  profileId?: number;
  type: string;
  displayName: string;
  URLImage: string;
  profileToken: string;
  defaultProfile: boolean;
  isKidsProfile: boolean;
  currentProfile: boolean;
  reco: boolean;
};

export type UserExternalServices = {
  ID: string;
  tvPackID: string;
  activationStatus: boolean;
};

export type UserSale = {
  paymentMeanIdSelected?: string;
  paymentMeans?: IPaymentMeans;
  userAddress?: UserSaleAddress;
  displayAddressForm?: boolean;
  availablePaymentMeans?: AvailablePaymentMeans[];
};

export type UserSaleAddress = {
  street2?: string;
  street3?: string;
  postalCode?: string;
  city?: string;
  countryId?: string;
  firstName?: string;
  proposals?: any[];
  timeoutId?: number;
  lastName?: string;
  civility?: string;
};

type AvailablePaymentMeans = {
  contractId: number;
  contractPaymentMeanId: number;
  contractPaymentMeanInfo: string;
  paymentMeanCode: string;
  paymentMeanLabel: string;
};

export type AccessibleChannel = {
  epgID: number;
  isStartOverAuthorized: boolean;
};
