export const DEFAULT_TIMEOUT_CALLBACK = 10000; // 10s
export const ERROR_CODE_RPC_SERVER = -32000;

export const ERROR_NO_WINDOW_DEFINED =
  'Error in Ifc initialize(): window is not defined.';
export const ERROR_NOT_INITIALIZED =
  'Error in Ifc. initialize() must be called before use rpc()';
export const ERROR_ALREADY_INITIALIZED =
  'Error in Ifc initialize(): Ifc is already initialized. You cannot initialize it again.';
export const ERROR_RPC = `Error Ifc on rpc() call '%1': %2`;
export const ERROR_RPC_TIMEOUT = `Timeout error. Response time exceeded %1 ms`;
